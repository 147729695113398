import REQ from '@/app/REQ'
import editAnnexDlg from "@/dialg/editAnnexDlg.vue"
import addDocPrjtStaffDlg from "@/dialg/addDocPrjtStaffDlg.vue"
import {mapGetters, mapState} from "vuex";
import {upWFoundById} from "@/utils/xyhUtils";
import {DOC_ROLE,DOC_STATE,DOC_TYPE,Doc_FILE_WAY} from "@/consts";
import docOptions from "./docOptions"
import replaceDocFileDlg from "@/doc/replaceDocFileDlg.vue"
import {wordViewUrl} from "@/doc/consts"
import editMaterialDlg from "@/doc/editMaterialDlg.vue"

const {DocPathsSMap} = docOptions

export default {
    name: 'doc',
    props:['id'],
    components:{editAnnexDlg, addDocPrjtStaffDlg, replaceDocFileDlg,editMaterialDlg},

    data() {
        return {
            docPaths:DocPathsSMap,
            doc: {},
            annexes:[],
            tplt:{},
            fileType:'',
            fileUrl: '',
            docAnnex:null,
            docStaffs:[],
            amIAuthor:false,
            amIPM:false,
            amIDocStaff:false,
            amIPrjtStaff:false,
            switchDlg:false,
            stateN:'',
            dept:{},
        }
    },

    computed: {
        ...mapState({
            orgId: ({ curOrg }) => curOrg?.id,
            myId: ({curStaff}) => curStaff?.id,
        }),
        ...mapGetters(['amIAdmin']),
    },
    created() {
        this.getDoc()
    },

    methods: {
        getDoc() {
            this.req2Data(REQ.doc, {id:this.id},null, ({docStaffs,staffs,doc,prjtStaffs})=>{
                if(!doc.state) doc.state = DOC_STATE.remarking
                if(this.tplt) this.tplt.name = doc.tpltId ? this.tplt.name:this.tplt.name + '(项目默认)'
                upWFoundById(docStaffs, staffs, 'staffId', (docStaff, staff) => docStaff.name = staff.name)
                if(doc.lastFileT) doc.fileDate = new Date(doc.lastFileT).YMDFmt()
                if(!doc.materials) doc.materials = []
                upWFoundById(doc.materials, staffs, 'adderId', (material, staff) => material.adderN = staff.name)
                let prjtStaff = prjtStaffs.find(it => it.staffId == this.myId)
                let docStaff = docStaffs.find(it => it.staffId == this.myId)
                this.amIPrjtStaff = prjtStaff
                this.amIPM = (prjtStaff && prjtStaff.role == DOC_ROLE.PM)
                this.amIDocStaff = docStaff
                this.amIAuthor = (docStaff && docStaff.role == DOC_ROLE.author)
                doc.url = XyhConf.HglSHost + '/' + REQ.previewDoc + '?id=' + doc.id + '&orgId=' + this.orgId + '&t=' + new Date().getTime()
                if(!doc.type) doc.type = DOC_TYPE.sop
            })
        },

        preview(fileType,url){
            this.fileType = fileType
            this.fileUrl = wordViewUrl + encodeURIComponent(url)
        },

        upLoadFile(file){
            this.editFile(file,'上传培训文件',studyFile=> {
                this.req2Data(REQ.replaceDocStudy, {id: this.id, studyFile}, {}, ()=>this.getDoc())
            })
        },

        editDocState(tipN){
            let tips = {remarking:'您是否确定起草已完成，将发给审阅者审阅', fixed:'您是否确定文档内容已经完稿，并将生成最终文件', issued: '本操作将会发布此文档'}
            this.confirm(tips[tipN],()=>{
                this.req2Data(REQ.upDocState,{id:this.id,state:DOC_STATE[tipN]},{},()=> this.getDoc())
            })
        },

        editDocAnnex(docAnnex){
            this.docAnnex = docAnnex
            this.showDlg('editAnnexDlg')
        },

        deleteDocAnnex(id){
            this.confirm('该操作将会删除此附件，确认吗？',()=>{
                this.req2Data(REQ.rmDocAnnex, {id},{}, ()=>this.getDoc())
            })
        },

        docStateBack(){
            let {stateN, id} = this
            if(this.chk2msg(stateN == '草稿' || stateN == '审阅','请填写正确的状态名')) return
                let state = (stateN == '草稿') ? DOC_STATE.draft:DOC_STATE.remarking
                this.req2Data(REQ.upDocState,{id, state},{},()=> {
                    this.getDoc()
                    this.switchDlg = false
                    this.stateN = ''
                })
        },

        removeDocStaff(docStaffId){
            this.req2Data(REQ.rmDocStaff,{docStaffId},{},()=>this.getDoc())
        },

        addMaterial(){
            this.showDlg('editMaterialDlg')
        },

        rmMaterial(docId, file){
            this.confirm('确认删除该文件吗？',()=>{
                this.req2Data(REQ.rmDocMaterial, {docId, file}, {}, ()=>this.getDoc())
            })
        },

        renewDocFile(id){
            this.confirm('确认重新生成文档文件吗',()=>{
                this.req2Data(REQ.genDocFile,{id},{},()=>this.getDoc())
            })
        },
    },

}
