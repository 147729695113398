import formDlgMix from "@/gDlgs/formDlgMix";
import REQ from "@/app/REQ";

export default {
	name: "editMaterialDlg",
	mixins: [formDlgMix],
	props: ['docId'],

	data() {
		return {
			uploadUrl:XyhConf.HglSHost +'/' + REQ.putFile,
			name:'',
			file:'',
			files:[]
		}
	},

	watch: {
		working(){
			Object.assign(this, {name:'', file:'', files: []})
		}
	},

	methods: {
		handleChange(file,fileList){
			if (fileList.length > 1) {
				fileList.splice(0, 1);
			}
			this.name = fileList[0].name.substring(0,fileList[0].name.indexOf("."))
		},

		successUpload(res){
			this.file = res.data.url
		},

		ok() {
			let {docId, name, file} =this
			if(this.chk2msg(file, '请上传文件',)) return
			this.reqThenBk(REQ.addDocMaterial, {docId, name, file})
		},
	}
};
